// colors
$colorPrimary: #6a73c7;

// media queries
@media screen and (max-width: 1023px,) {
    .navRight {
        display: none !important;
    }
}
@media screen and (min-width: 1024px,) {
    .links {
        display: none !important;
    }
}
@media screen and (max-width: 600px) {
    .notifications {
        img {
            width: 200px !important;
            border-radius: 100px;
            margin-bottom: 30px;
            margin-top: 20px;
        }
        align-items: center;
        flex-direction: column !important;
        .card {
            margin-right: 15px;
            margin-bottom: 20px;
            .intro-text {
                padding: 20px 20px;
                font-size: 18px !important;
            }
        }
    }
    .contactMeIntro {
        margin-bottom: 20px !important;
    }
    .center {
        width: 90%;
        margin-left: 5%;
    }
    .thankyou {
        text-align: center;
    }
}
// styles
html {
    scroll-behavior: smooth;
}
html,
body {
    margin: 0px;
}
.navbarIcon {
    img {
        border-radius: 30px;
        box-shadow: 1px 1px 10px rgb(196, 196, 196);
    }
    img:active {
        transform: scale(0.95);
    }
}
.navRight {
    .navbar-item {
        font-size: 18px;
    }
    .navMiddle {
        margin-right: 50px;
        margin-left: 50px;
    }
}

.myButton {
    border: none;
    background-color: white;
    font-size: 25px !important;
    margin-top: -60px !important;
}
.myButton:active {
    color: $colorPrimary;
}
.links {
    margin-top: 20px;
    width: 100%;
    display: flex;
    align-self: center;
    justify-content: space-evenly;
}
.link {
    color: #4a4a4a;
    font-size: 18px;
}
.link:active {
    color: $colorPrimary;
    transform: scale(0.95);
}
.link:hover {
    color: $colorPrimary;
}

.introContainer {
    .title {
        text-align: center;
        font-weight: 500;
        font-size: 35px;
    }
    .subtitle {
        font-size: 24px;
        text-align: center;
    }
    .notifications {
        box-shadow: 1px 1px 10px rgb(196, 196, 196);
        display: flex;
        flex-direction: row;
        margin-top: 50px;
        padding: 2%;
        padding-top: 5%;
        padding-bottom: 5%;
        background-color: $colorPrimary;
        img {
            box-shadow: 1px 1px 10px rgb(196, 196, 196);
            width: 250px;
            border-radius: 125px;
        }
        div {
            display: flex;
            flex-direction: row;
            .intro-text {
                align-items: center;
                padding-left: 15px;
                padding-right: 15px;
                font-size: 20px;
            }
        }
    }
}

.section {
    margin-top: 70px;
    margin-left: 10px;
    margin-right: 10px;
    .columns {
        box-shadow: 1px 1px 10px rgb(196, 196, 196);
        border-radius: 5px;
    }
    .columns .column {
        padding: 50px 30px;
        border: 1px solid #e6ecf8;
        border-radius: 5px;
        text-align: center;
        i {
            font-size: 45px;
            color: $colorPrimary;
        }
        .heading {
            margin-top: 25px;
            font-size: 18px;
            font-weight: 500;
            color: $colorPrimary;
        }
        .columnContent {
            font-size: 17px;
            padding-top: 10px;
        }
    }
}

.projectContainer {
    align-items: center;
    margin-top: 30px !important;
    display: flex;
    flex-direction: column;
    .projectDisplay {
        .tile {
            margin-bottom: 30px;
            flex-direction: column;
            align-items: center;
            .heading {
                text-align: center;
                font-size: 18px;
                font-weight: 500;
            }
            .projectDescription {
                text-align: center;
                .booksApp {
                    height: 500px;
                }
                img {
                    height: 400px;
                    border: 1px solid #e6ecf8;
                }
                p {
                    font-size: 17;
                    margin-right: 20px;
                    margin-left: 20px;
                }
            }
        }
    }
}
.aboutMeContainer {
    background-color: $colorPrimary;
    padding: 60px;
    margin-top: 40px;
    text-align: center;
    .card {
        padding: 20px;
        .aboutMeText {
            font-size: 30px;
        }
        p {
            font-size: 18px;
            margin-bottom: 10px;
        }
        a {
            font-size: 20px;
            color: $colorPrimary;
            div {
                i {
                    margin-right: 5px;
                    font-size: 25px;
                }
            }
            div:active {
                transform: scale(0.95);
            }
        }
        a:hover {
            color: #4a4a4a;
        }
    }
}

.footer {
    margin-top: 50px;
    text-align: center;

    .logo {
        margin-bottom: 10px;
        img {
            width: 80px;
        }
        img:active {
            transform: scale(0.95);
        }
    }
    .icons {
        font-size: 25px;
        padding-top: 10px;
        a {
            color: #4a4a4a;
            margin-left: 10px;
            margin-right: 10px;
        }
        a:hover {
            color: $colorPrimary;
        }
        a:active {
            transform: scale(0.9);
        }
    }
}

.formContainer {
    margin-top: 100px;
    margin-bottom: 100px;
    .contactMeIntro {
        text-align: center;
        margin-bottom: 50px;
        text-transform: uppercase;
        h1 {
            font-size: 32px !important;
        }
    }
    .center {
        display: flex;
        flex-direction: row;
        justify-content: center;
        label {
            font-weight: 500;
            font-size: 18px;
            color: $colorPrimary;
        }
        input {
            margin-bottom: 7px;
        }
        button {
            margin-top: 10px;
            width: 100%;
            background-color: $colorPrimary;
            color: white;
        }
        button:active {
            background-color: white;
            color: $colorPrimary;
        }
    }
}

.thankyouContainer {
    height: 600px;
    .thankyouSection {
        height: 700px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        button {
            border: none;
            background-color: white;
            img {
                width: 100px;
            }
            img:active {
                transform: scale(0.95);
            }
        }
    }
}
